import React from 'react';

export const ContactMap = () => {
  return (
    <iframe
      width="100%"
      height="400px"
      frameBorder="0"
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDPhAQAT-Tx0QC2JoqAgnBSiiksLUfwnp8&q=The%20Aphex%20Group"
      allowFullScreen
    ></iframe>
  );
};
