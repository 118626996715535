import React from 'react';

import AphexLogo from '../assets/svg/aphex-logo-lg.svg';
import { ContactDetails } from './contact-detail';
import { ContactSectionCards } from './contact-section-cards';

import './contact-section.scss';

/**
 * Middle section of contact page
 */
export const ContactSection = () => {
  return (
    <div className="contact-section">
      <div>
        <AphexLogo />
        <ContactDetails />
      </div>
      <ContactSectionCards />
    </div>
  );
};
