import React from 'react';
import { ModalAlert } from './modal';
import BackgroundImage from '../assets/svg/contact-form-bg.svg';

import './contact-form.scss';

async function postData(url, data, token) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Token: token
    },
    body: JSON.stringify(data)
  });
  return response.text();
}

const resetState = {
  firstName: '',
  lastName: '',
  email: '',
  subject: '',
  message: ''
};

const isReady = () =>
  typeof window !== 'undefined' &&
  typeof window.grecaptcha !== 'undefined' &&
  typeof window.grecaptcha.render === 'function';

export class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...resetState,
      responseMessage: '',
      sendingMessage: false,
      grecaptchaToken: null,
      grecaptchaReady: isReady()
    };
    this.readyCheck = null;
    if (!this.state.grecaptchaReady) {
      this.readyCheck = setInterval(this.updateReadyState, 500);
    }
  }

  componentDidMount() {
    window.handleRecaptcha = this.handleRecaptcha;
    window.expireRecaptcha = this.expireRecaptcha;
    if (this.state.grecaptchaReady) {
      this.renderGrecaptcha();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.grecaptchaReady && !prevState.grecaptchaReady) {
      this.renderGrecaptcha();
    }
  }

  componentWillUnmount() {
    clearInterval(this.readyCheck);
    window.handleRecaptcha = null;
    window.expireRecaptcha = null;
  }

  updateReadyState = () => {
    if (isReady()) {
      this.setState({
        grecaptchaReady: true
      });
      clearInterval(this.readyCheck);
    }
  };

  renderGrecaptcha = () => {
    if (!process.env.GATSBY_GOOGLE_RECAPTCHA_TOKEN) {
      console.error('GATSBY_GOOGLE_RECAPTCHA_TOKEN is not set');
    } else {
      window.grecaptcha.render('contact-form-recaptcha', {
        sitekey: process.env.GATSBY_GOOGLE_RECAPTCHA_TOKEN,
        callback: 'handleRecaptcha',
        'expired-callback': 'expireRecaptcha'
      });
    }
  };

  setValue = event => {
    event.preventDefault();
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  submitForm = async event => {
    event.preventDefault();
    const {
      grecaptchaToken,
      firstName,
      lastName,
      email,
      message,
      subject
    } = this.state;
    if (!grecaptchaToken) {
      return;
    }
    try {
      this.setState({
        sendingMessage: true
      });
      const data = { firstName, lastName, email, message, subject };
      await postData(
        `${process.env.GATSBY_API_ENDPOINT}/message`,
        data,
        grecaptchaToken
      );
      this.setState({
        responseMessage: 'Successfully sent your message to the Aphex Group.',
        ...resetState
      });
    } catch (error) {
      console.log(error);
      this.setState({
        responseMessage:
          'Failed to send message, try checking your connection and resending.'
      });
    }
    this.setState({
      sendingMessage: false
    });
  };

  clearMessage = () => {
    this.setState({
      responseMessage: ''
    });
  };

  handleRecaptcha = token => {
    this.setState({
      grecaptchaToken: token
    });
  };

  expireRecaptcha = () => {
    this.setState({
      grecaptchaToken: null
    });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      message,
      subject,
      responseMessage,
      sendingMessage,
      grecaptchaToken
    } = this.state;
    return (
      <>
        <div className="contact-form-bg">
          <BackgroundImage />
          <div className="contact-form-inner">
            <h1>contact the aphex group</h1>
            <form onSubmit={this.submitForm}>
              <input
                className="form-control"
                type="text"
                name="firstName"
                value={firstName}
                onChange={this.setValue}
                id="first-name"
                placeholder="First Name"
                maxLength={60}
                autoComplete="given-name"
                required
              />
              <input
                className="form-control"
                type="text"
                name="lastName"
                value={lastName}
                onChange={this.setValue}
                id="last-name"
                placeholder="Last Name"
                autoComplete="family-name"
                maxLength={60}
              />
              <input
                className="form-control"
                type="email"
                name="email"
                value={email}
                onChange={this.setValue}
                id="email"
                autoComplete="email"
                placeholder="Email"
              />
              <select
                className="form-control"
                name="subject"
                value={subject}
                onChange={this.setValue}
                id="subject"
                required
              >
                <option value="">Please select your area of interest</option>
                <option value="Recruitment">Recruitment</option>
                <option value="Chemishield">Chemishield</option>
                <option value="Validation">Validation</option>
              </select>
              <textarea
                className="form-control"
                name="message"
                value={message}
                onChange={this.setValue}
                id="message"
                rows="5"
                placeholder="Message"
                maxLength={280}
                required
              />
              <div id="contact-form-recaptcha"></div>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={sendingMessage || !grecaptchaToken}
              >
                SUBMIT
              </button>
            </form>
          </div>
        </div>
        <ModalAlert title="" closeModal={this.clearMessage}>
          {responseMessage}
        </ModalAlert>
      </>
    );
  }
}
