import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const ModalAlert = props => {
  const { closeModal, title, children, className } = props;

  return (
    <div>
      <Modal
        isOpen={!!children}
        toggle={closeModal}
        className={className}
        centered
      >
        <ModalHeader toggle={closeModal}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={closeModal}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
