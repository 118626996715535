import React from 'react';
import { NavBar } from '../components/navbar';

import '../components/fa-icons';
import '../scss/global.scss';
import { Footer } from '../components/footer';
import { ContactForm } from '../components/contact-form';
import { ContactSection } from '../components/contact-section';
import { ContactMap } from '../components/contact-map';
import { CookieConsent } from '../components/cookie-consent';
import { Head } from '../components/head';

export default function () {
  return (
    <>
      <Head
        title="The Aphex Group - Contact Us"
        description="Get in touch with The Aphex Group and find out more on our recruitment, validation, software or web design services."
      />
      <CookieConsent />
      <NavBar />
      <ContactForm />
      <ContactSection />
      <ContactMap />
      <Footer />
    </>
  );
}
