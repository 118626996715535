import React from 'react';

import recruitmentCard from '../assets/recruitment-card.png';
import chemishieldCard from '../assets/chemishield-card.png';
import validationCard from '../assets/validation-card.png';

import './contact-section-cards.scss';

/**
 * Links to each Aphex Group division, images to the right in the middle section of contact page
 */
export const ContactSectionCards = () => {
  return (
    <div className="contact-section-cards">
      <a href="https://recruitmentbyaphex.com">
        <img src={recruitmentCard} alt="Recruitment" />
      </a>
      <a href="https://chemishield.com">
        <img src={chemishieldCard} alt="Chemishield" />
      </a>
      <a href="https://validationbyaphex.com">
        <img src={validationCard} alt="Validation" />
      </a>
    </div>
  );
};
